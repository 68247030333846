import React from 'react'
import './Header.scss'
import Container from '../Container'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navOpen: false
    }
  }

  showMobileNav = event => {
    const navOpen = this.state.navOpen
    this.setState({
      navOpen: !navOpen
    })
  }

  render () {
    return (
      <header
        className={
          ('site-header shadowed') + 
          (this.state.navOpen ? ' nav-mobile-open': '')
        }
      >
        <Container>
          <div className="site-branding">
            <a href={'/'} className="site-title">
              {this.props.siteMeta.name}
            </a>
          </div>
          <nav className="site-nav">
            <ul className="nav-list">
              <li className="nav-item">
                <a href="/image-to-base64-converter/">Image to Base64</a>
              </li>
              <li className="nav-item">
                <a href="/blog/">Blog</a>
              </li>
              <li className="nav-item">
                <a href="https://www.base64decoder.io" target="_blank">
                  Base64Decoder.io
                </a>
              </li>
            </ul>
          </nav>
          <button className="nav-opener" onClick={this.showMobileNav}>
            <span className="menu"></span>
          </button>
          <nav className="nav-mobile">
            <ul className="nav-mobile-list">
              <li className="nav-mobile-item">
                <a href="/image-to-base64-converter/" className="nav-mobile-link">Image to Base64</a>
              </li>
              <li className="nav-mobile-item">
                <a href="/blog/" className="nav-mobile-link">Blog</a>
              </li>
              <li className="nav-mobile-item">
                <a href="https://www.base64decoder.io" className="nav-mobile-link" target="_blank">
                  Base64Decoder.io
                </a>
              </li>
            </ul>
          </nav>
        </Container>
      </header>
    )
  }
}

export default Header
